.product_container {
    padding-bottom: 0px !important;
}

.pagination {
    justify-content: flex-end;
    margin-top: 40px !important;
}

.pagination li {
    margin-left: 10px;
}

.pagination li a {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    display: block;
    border: 1px solid rgb(0, 0, 0);
    text-align: center;
    font-size: 11px;
}

.pagination li:first-child a,
.pagination li:last-child a {
    width: auto;
    height: auto;
    border: none;
    text-transform: uppercase;
}

.pagination li.active a {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255) !important;
}

.filter_cont {
    overflow: hidden;
    transition: max-height 0.6s ease;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}



.filter_cont {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
}

.filter_cont.show {
    transition: max-height 0.6s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
}
.product_tag_list {
    margin: 10px 0px;
}
.product_tag_list li {
    list-style-type: none;
    padding: 2px 8px;
    font-family: 'FuturaNormal';
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    margin: 4px 8px 4px 0px;
    border-radius: 4px;
    background-color: rgba(247, 247, 247, 1);
    box-shadow: 0px 4px 8px rgb(195 195 195 / 70%);
    display: inline-flex;
    align-items: center;
}
.product_tag_list li i {
    color: rgb(0 0 0);
    margin-left: 8px;
    font-size: 13px;
    cursor: pointer;
}
.product_tab_btn button {
    font-family: 'FuturaMedium';
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    line-height: 14px;
    border: none;
    background-image: linear-gradient( 
90deg
 , rgba(255, 243, 166, 1) 0%, rgba(249, 223, 123, 1) 0%, rgba(221, 183, 78, 1) 0%, rgba(181, 126, 16, 1) 0%, rgba(238, 194, 93, 1) 0%, rgba(235, 188, 92, 1) 41%, rgba(225, 170, 58, 1) 95%);
    border-radius: 5px;
    padding: 10px 15px;
}

@media (max-width: 767px) {
    .pagination {
        display: block !important;
    }

    .pagination li {
        display: inline-block !important;
        margin-bottom: 5px !important;
    }

    .product_filter_cont {
        padding: 10px 20px;
        max-height: 400px;
        overflow: auto;
    }

    .product_filter {
        position: fixed;
        bottom: 0px;
        background-color: rgb(255 255 255);
        left: 0px;
        width: 100%;
        z-index: 1;
        border-radius: 15px 15px 0px 0px;
        box-shadow: 0px -3px 6px rgb(160 160 160 / 40%);
        overflow-y: auto;
        max-height: 0;
        transition: 0.9s all ease-in-out;
        z-index: 11;
    }

    .filter_show {
        transition: 0.9s all ease-in-out;
        height: auto;
        max-height: 450px;
    }
    .product_filter_close {
        border: none;
        background-color: rgb(0 0 0);
        border-top: 1px solid rgb(180, 180, 180);
        text-align: center;
        color: rgb(255,255,255);
        font-size: 15px;
        font-family: 'FuturaNormal';
        padding: 10px;
        text-align: center;
    }
    .flex_center {
        display: flex;
        align-items: center;
    }
    .filler {
        flex-grow: 1;
    }
    .product_filter_open {
        border: none;
        background-color: rgb(0 0 0);
        border-top: 1px solid rgb(180, 180, 180);
        color: rgb(255,255,255);
        font-size: 15px;
        font-family: 'FuturaNormal';
        padding: 5px 15px;
        text-align: center;
        border-radius: 2px;
    }
                .modal-shadow {
        position: relative;
        overflow: hidden;
                }
.modal-shadow::before {
        position: fixed;
        content: '';
        background-color: rgb(0 0 0 / 40%);
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100vh;
        z-index: 10;
}
}