.invalid-mob {
  display: block !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.register-error {
  font-size: 0.875em;
  color: #dc3545;
}

input[type="number"] {
  -moz-appearance: textfield;
}
