.polki_main .divider_main {
    display: none;
}
.polki_container {
    padding: 50px 0px;
    background-image: url('../../../assets/images/polki-bg.png');
    background-repeat: repeat;
}
.polki_box {
    margin-bottom: 35px;
}
.polki_box:last-child {
    margin-bottom: 0px;
}
.polki_box img {
    width: 100%;
    box-shadow: 0px 0px 6px rgb(191 191 191 / 38%);
}
.polki_btn {
    display: flex;
    align-items: center;
    justify-content: center;
}
.polki_btn a {
    font-family: 'FuturaMedium';
    font-size: 16px;
    color: rgba(255, 255, 255, 1) !important;
    text-decoration: none !important;
    border: none;
    background-image: linear-gradient(90deg, rgba(255, 243, 166, 1) 0%, rgba(249, 223, 123, 1) 0%, rgba(221, 183, 78, 1) 0%, rgba(181, 126, 16, 1) 0%, rgba(238, 194, 93, 1) 0%, rgba(235, 188, 92, 1) 41%, rgba(225, 170, 58, 1) 95%);
    border-radius: 5px;
    padding: 25px 5px;
    text-transform: uppercase;
    width: 260px;
    display: inline-block;
    text-align: center;
}
.polki_btn a:first-child {
    margin-right: 50px;
}
.polki_btn a:last-child {
    margin-left: 50px;
}

/* Media Query */

@media (max-width: 767px) {
    .polki_btn {
        display: block;
        text-align: center;
    }
    .polki_btn a:first-child {
        margin: 0px 0px 30px 0px;
    }
    .polki_btn a:last-child {
        margin: 0px;
    }
}

.polki_collection {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 1) !important;
    font-weight: 400;
}