.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.bbb {
  background-color: green;
}
#rcc-decline-button {
  background: rgb(73 79 80) !important;
  color: rgb(233 238 241) !important;
}
#rcc-confirm-button {
  background: rgb(233 238 241) !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(40, 44, 52);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: rgb(97, 218, 251);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.alert {
  width: 300px !important;
  position: fixed !important;
  bottom: 10px !important;
  right: 20px !important;
  z-index: 9;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 50%);
}

@media (max-width: 768px) {
  .CookieConsent {
    display: block !important;
  }
}
.clickPrivacy {
  color: rgb(102, 176, 255);
  text-decoration: underline;
}
.clickPrivacy:hover {
  color: rgb(102, 176, 255);
}

.CookieConsent {
  background-image: linear-gradient(
    45deg,
    rgb(0 0 0) 30%,
    transparent 90%
  ) !important;
  /* font-family: 'FuturaNormal' !important; */
  font-size: 17px !important;
  font-weight: 400 !important;
  color: rgb(255 255 255) !important;
}
#rcc-confirm-button {
  /* font-family: 'FuturaMedium' !important; */
  font-size: 15px !important;
  color: rgb(255 255 255) !important;
  text-transform: capitalize;
  padding: 4px 10px !important;
  border-radius: 4px !important;
  background-image: linear-gradient(
    90deg,
    rgba(255, 243, 166, 1) 0%,
    rgba(249, 223, 123, 1) 0%,
    rgba(221, 183, 78, 1) 0%,
    rgba(181, 126, 16, 1) 0%,
    rgba(238, 194, 93, 1) 0%,
    rgba(235, 188, 92, 1) 41%,
    rgba(225, 170, 58, 1) 95%
  ) !important;
}

@media (max-width: 767px) {
  #rcc-confirm-button {
    float: right;
  }
}

@media (min-width: 768px) {
  .CookieConsent {
    padding-left: 75px;
  }
  #rcc-confirm-button {
    margin-right: 75px !important;
  }
}
