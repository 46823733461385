.contact_branch_main {
    border-top: 1px dashed rgb(69 73 79);
    padding-top: 50px;
    margin-top: 60px;
}

.contact_location_head {
    font-family: 'GFS Didot', serif;
    font-size: 30px;
    color: rgba(0, 0, 0, 1);
    line-height: 30px;
    margin-bottom: 30px;
}

.contact_location_ip select {
    float: left;
    width: 100%;
    border: 1px solid rgba(118, 118, 118, 1);
    border-radius: 5px;
    height: 60px;
    padding: 0px 20px;
    outline: none;
    background-color: transparent;
     -webkit-appearance: menulist !important;
}

.branch_box {
    background-color: rgba(248, 249, 250, 1);
    border: 1px solid rgba(230, 232, 235, 1);
    border-radius: 5px;
    padding: 10px 15px;
    margin-top: 40px;
}

.branch_image img {
    width: 100%;
}

.branch_box .row {
    align-items: center;
}

.branch_box .col-sm-6:last-child {
    border-left: 1px dashed rgb(69 73 79);
}

.branch_box .col-sm-6 {
    padding: 0px 30px;
}

@media (max-width: 767px) {
    .branch_image {
        margin-bottom: 30px;
    }

    .contact_location_ip .col-sm-6:first-child {
        margin-bottom: 20px;
    }
}