.disable {
  pointer-events: none;
  opacity: 0.5;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.shipping_fields input,
.shipping_fields select {
  background-color: transparent;
}

.upload-btn-wrapper button {
  min-height: 280px;
}

.shipp_box {
  background-color: rgb(243 243 243);
  box-shadow: 0 1px 1px 1px rgb(0 0 0 / 16%);
  border-radius: 3px;
  padding: 15px 0px;
}

.xmark_input {
  position: relative;
}
.xmark_input input {
  padding-right: 35px;
}
.xmark {
  position: absolute;
  right: 5px;
  top: 40%;
  transform: translateY(-50%);
}

.valid-feedback {
  color: #066720 !important;
}
.input_label {
  border: 1px solid rgba(118, 118, 118, 1);
  border-radius: 5px;
  outline: none;
  padding: 14px 15px;
  /* font-size: 14px; */
  margin-bottom: 0px !important;
  height: 50px;
}

.input_box_color {
  color: black;
}

.save_address_btn {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 5px;
  border: none;
  text-align: center;
  color: rgb(255, 255, 255);
  height: 50px;
  width: 200px;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 30px;
  outline: none;
  font-family: "FuturaNormal";
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .shipping_fields .pr-5 {
    padding-right: 0px !important;
  }
}
.shipping_btn1 {
  pointer-events: none;
}
