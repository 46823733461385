.zoom_area>div>div:last-child img {
    z-index: 9;
    position: relative;
}

.dispay-feedback {
    display: block !important;
}

.detail_left img.example {
    width: 100%;
}

.detail_left_more {
    padding-left: 20%;
}
.display3{
    opacity: 0.5;
    
}
.detail_more li video {
    background-color: rgb(0, 0, 0);
}

.pincode_left {
    float: left;
    width: 70%;
}

.tab_collapse {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
}

.tab_collapse.show {
    transition: max-height 0.6s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
}

.product_mobile {
    /* padding: 0px 25px; */
    margin-bottom: 40px;
}
.product_mob_slide img {
    width: 100%;
}

.product_mobile .slick-prev:before, .product_mobile .slick-next:before {
    color: rgb(0 0 0);
    font-size: 18px;
}
.product_mobile .slick-prev {
    left: -20px;
}
.product_mobile .slick-next {
    right: -20px;
}
.mobile_product_slected img {
    width: 100%;
}
#productModal .modal-body {
    padding: 0.3rem;
}
#productModal button.close {
    opacity: 1;
    position: absolute;
    right: 5px;
    top: 5px;
}
.product_mobile .slick-track {
    display: flex !important;
    align-items: center;
    background-color: #000;
}
.mobile_product_slected {
    display: flex;
    min-height: 98vh;
    align-items: center;
}
.select_size {
    background-color: rgb(120,120,120);
    border: none;
    border-radius: 4px;
    color: rgb(255,255,255);
    font-size: 15px;
    outline: none;
}
@media (max-width: 767px) {
    .detail_left_more {
        padding-left: 0%;
    }

    .detail_more {
        position: relative !important;
        width: 100% !important;
        left: auto !important;
        top: auto !important;
        flex-direction: row !important;
        height: auto !important;
        margin-top: 1%;
        justify-content: normal !important;
    }

    .detail_more li {
        width: 24% !important;
    }
}

.button_back {
    color: rgba(255, 255, 255, 1) !important;
    background-color: rgb(0, 0, 0) !important;
}

.button_back1 {
    color:  rgb(0, 0, 0) !important;
   background-color: rgba(228, 228, 228, 1) !important;
}




.product_list {
    position: relative;
}
.product_list .cartpg {
    position: absolute !important;
    height: 100% !important;
        z-index: 1 !important;
}
.product_list .cartpg img {
    position: absolute !important;
    top: 150px !important;
}
.detail_pin_label_desc {
    margin-top: 20px;
}

