.whatsapp_icon {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 999999;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 57%);
    border-radius: 50%;
}


.jewel_header {
    align-items: center;
}

.jewel_head {
    width: 20%;
    
}
.jewel_head{
    font-family: 'FuturaMedium';
font-size: 18px;
font-weight: 500;
color: rgba(0, 0, 0, 1);
text-transform: uppercase;

}


.jewel_header{
    display: flex !important;
    padding: 10px 50px 20px 50px;
  
}

.jewel_list {
    display: flex;
    width: 80%;
}
.jewel_list li{
        margin-right: 65px;
        font-family: 'FuturaNormal';
        font-size: 14px;
        font-weight: 400;
}

.seo_cont {
    font-size: 16px;
    color: rgba(89, 89, 89, 1);
    letter-spacing: 0px;
    line-height: 24px;
    font-family: 'FuturaNormal';
    margin-top: 5px;
    text-align: justify;
    word-break: break-word;
}
.seo_cont h1, .seo_cont h2 {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 1) !important;
}
.seo_cont h3 {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1) !important;
}

.seo_cont h1, .seo_cont h1 a, .seo_cont h1, .seo_cont h2 a, .seo_cont h1, .seo_cont h1 a, .seo_cont h1, .seo_cont h3 a {
    color: rgba(0, 0, 0, 1) !important;
}
.seo_cont p {
    margin-bottom: 25px;
}
.seo_cont a{
     color: rgba(0, 0, 0, 1) !important;
   
}
.seo_subCat{
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 1) !important;

}

@media (max-width: 767px) {
    .whatsapp_icon {
        left: 10px;
        bottom: 10px;
    }

    .footer_cont {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
    }

    .footer_cont_show {
        transition: max-height 0.6s cubic-bezier(1, 0, 1, 0);
        height: auto;
        max-height: 9999px;
    }
}