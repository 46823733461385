.cart_front {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

@media (min-width: 320px) and (max-width: 479px) {
    .cart_front {
        display: block !important;
    }
}