.aprove {
    color: green;
    text-align: right;
}
.video_shop li a {
    color: rgba(10, 126, 209, 1) !important;
}

.read {
    cursor: pointer;
    pointer-events: visible;
}
.readmore {
    pointer-events: none;
}