.order_rate b {
  font-family: "GFS Didot", serif;
  font-weight: 400;
}
.order_btn .download_invoice {
  margin-bottom: 15px !important;
}
.upload_pan_btn {
  margin-top: 15px;
}
.order_btn button {
  align-items: center;
  display: flex;
  justify-content: center;
}

.button-container {
  display: flex;
  justify-content: space-between;
}
.return-button-container {
  justify-content: space-between;
}
.cancel_order_btn {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 5px;
  border: none;
  text-align: center;
  color: rgb(255, 255, 255);
  padding: 5px 10px;
  margin-top: 10px;
  margin-bottom: 30px;
  outline: none;
  font-family: "FuturaNormal";
  font-size: 18px;
  font-weight: 500;
}
.cancel_return_btn {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 5px;
  border: none;
  text-align: center;
  color: rgb(255, 255, 255);
  padding: 5px 20px;
  outline: none;
  font-family: "FuturaNormal";
  font-size: 18px;
  font-weight: 500;
}
.rating {
  /* margin-left: -155px; */
  /* margin-top: 270px; */
  margin-top: -23px;
  margin-left: 215px;
}

#trackModal .modal-body {
  padding: 25px;
}
#trackModal button.close {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
#trackModal button.close img {
  width: 30px;
  height: 30px;
}
.track_intro {
  background-color: rgba(250, 250, 250, 1);
  border-radius: 10px;
  padding: 25px 32px;
  margin-bottom: 32px;
}
.track_intro li {
  list-style-type: none;
  color: rgba(122, 122, 122, 1);
  font-family: "FuturaNormal";
  font-size: 16px;
  margin-bottom: 10px;
}
.track_intro li:last-child {
  margin-bottom: 0px;
}
.track_intro li span {
  color: rgb(0, 0, 0);
}

.track_details {
  background-color: rgba(250, 250, 250, 1);
  border-radius: 10px;
}
.track_flow {
  border-bottom: 1px solid rgb(228 228 228);
  padding: 15px 0px;
}
.track_flow li {
  list-style-type: none;
  width: 20%;
  display: inline-block;
  text-align: center;
  position: relative;
}
.track_flow li::before {
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  left: -102px;
  top: 63px;
  background-color: rgba(184, 184, 184, 1);
}

.track_flow li:first-child::before {
  display: none;
}
.track_flow li.active .track_icon {
  background-color: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
}
.track_flow li.active::before {
  background-color: rgb(0, 0, 0);
}
.track_flow .track_icon {
  width: 15px;
  height: 15px;
  background-color: rgba(250, 250, 250, 1);
  border: 1px solid rgba(184, 184, 184, 1);
  display: inline-block;
  border-radius: 50%;
  margin-top: 30px;
  position: relative;
}
.track_txt {
  color: rgba(122, 122, 122, 1);
  font-family: "FuturaNormal";
  font-size: 18px;
}
.track_cont {
  padding: 15px;
}
.track_box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
.track_box:last-child {
  margin-bottom: 0px;
}
.track_date {
  color: rgb(0 0 0);
  font-family: "FuturaNormal";
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  width: 20%;
}
.track_list {
  width: 80%;
  background-color: rgb(255, 255, 255);
  padding: 18px 20px;
  border-radius: 10px;
}

.track_list li {
  list-style-type: none;
  color: rgb(0 0 0);
  font-family: "FuturaNormal";
  font-size: 16px;
  margin-bottom: 10px;
}
.track_list li:last-child {
  margin-bottom: 0px;
}
.track_list li span {
  color: rgb(122 122 122);
}

/* return popup */

.return_order_popup .enquiry_head {
  font-size: 30px;
  margin-bottom: 0px;
}
.return_order_popup .cancel_show {
  text-align: center;
  font-size: 16px;
  padding: 10px;
}

.return_order_holder .order_image {
  width: 42% !important;
}

.return_order_holder .order_desc {
  width: 65%;
  padding: 10px !important;
}
.return_order_holder .order_btn.flt {
  padding: 10px !important;
}

.return_order_holder .order_descss {
  width: 40%;
  padding: 10px !important;
}

.return_order_holder .order_btn .report_problem {
  margin-bottom: 15px !important;
}

.order_return_main .nav {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid rgb(187 187 187);
  margin-bottom: 25px;
}

.order_return_main .nav::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: transparent;
}
.order_return_main .nav::-webkit-scrollbar {
  width: 1px;
  height: 0px;
  background-color: transparent;
}
.order_return_main .nav::-webkit-scrollbar-thumb {
  background-color: transparent;
}
/* end */

@media (min-width: 320px) and (max-width: 479px) {
  .order_main .nav {
    overflow: none !important;
  }
  .track_flow li {
    display: block;
  }
  .track_flow li::before {
    left: -12px;
    top: 25px;
    transform: rotate(90deg);
    width: 75px;
  }
  .track_flow .track_icon {
    z-index: 1;
  }
  .track_txt {
    position: absolute;
    width: 180px;
    bottom: 3px;
  }
  .track_box {
    display: block;
  }
  .track_date {
    width: 100%;
    margin: 10px 0px;
  }
  .track_list {
    width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .order_main .nav {
    overflow: none !important;
  }
  .track_flow li {
    display: block;
  }
  .track_flow li::before {
    left: 0px;
    top: 24px;
    transform: rotate(90deg);
  }
  .track_flow .track_icon {
    z-index: 1;
  }
  .track_txt {
    position: absolute;
    width: 180px;
    bottom: 3px;
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .order_main .nav {
    overflow: none !important;
  }
  #trackModal .modal-dialog {
    max-width: 99%;
  }
  .track_flow li::before {
    left: -52px;
    top: 64px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #trackModal .modal-dialog {
    max-width: 730px;
  }
  .track_flow li::before {
    left: -61px;
    top: 64px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .track_flow li::before {
    left: -68px;
  }
}
.cancelorder {
  padding: 20px;
}
/* .cancel_show {
  font-size: 14px;
  padding: 10px;
} */
.button-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}
