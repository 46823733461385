.gold_rate_container {
  padding: 50px;
  display: flex;
}

.gold_rate_collection {
  border: 50px;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-family: "FuturaNormal";
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}
