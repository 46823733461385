.policy_desc p u {
    font-family: 'FuturaMedium';
    font-size: 20px;
    margin-bottom: 10px;
    color: rgb(0,0,0);
    text-transform: capitalize;
    display: block;
    text-decoration: none;
}
.policy_desc p u b {
    font-weight: 500;
    font-style: normal;
}
@media (min-width: 768px) {
    .faq_container {
        min-height: calc(100vh - 474px);
    }
}
